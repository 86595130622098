import { APIPATHENV } from "./env"

const APIPATH = APIPATHENV
const getHeader = (token) => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
    }
    if (token) {
        headers['x-access-token'] = token
    }
    return headers
}

const ACTION = {
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
}

const commonReducer = (state, action) => {
    switch (action.type) {
        case ACTION.LOADING: {
            return {
                ...state,
                data: action.payload,
                loading: true,
                error: false,
                success: false,
            }
        }
        case ACTION.ERROR: {
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: true,
                success: false,
            }
        }
        case ACTION.SUCCESS: {
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
                success: true,
                totalRecords: action.total
            }
        }
        default: {
            return {
                ...state,
                error: true,
            }
        }
    }
}

export { APIPATH, ACTION, getHeader, commonReducer }
