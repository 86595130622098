import { Business as BusinessIcon, PrintOutlined as PrintOutlinedIcon } from "@mui/icons-material";
import { RequiredAuth } from "../auth/requiredAuth";
import Business from "../pages/business/business";
import { Devices } from "../pages/devices/devices";

const businessRoutes = {
    id: 'Businesses',
    // path: '/dashboard',
    // header: "Pages",
    icon: <BusinessIcon />,
    path: '/business',
    containsHome: true,
    children: null,
    component: Business,
    guard: RequiredAuth,
}

const deviceRoutes = {
    id: 'Devices',
    icon: <PrintOutlinedIcon />,
    path: '/devices',
    containsHome: true,
    children: null,
    component: Devices,
    guard: RequiredAuth,
}

export const dashboardRoutes = [
    businessRoutes,
    deviceRoutes
]

export const sidebarRoutes = [
    businessRoutes,
    deviceRoutes
]