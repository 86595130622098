import styled from '@emotion/styled'
import { Box } from '@mui/system';
import Nav from './Nav';
import Sidebar from "./SideBar";

const drawerWidth = 200
const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`
const Root = styled.div`
  display: flex;
  min-height: 100vh;
`

const AppContent = styled('div')({
  flex: "1",
  display: "flex",
  flexDirection: "column",
  maxWidth: "100%"
})

const CustomBox = styled(Box)(({ theme }) => ({
  padding: 3,
  flex: 1,
  background: theme.palette.grey[100]
}));

export function Dashboard({ children, routes }) {
  return <Root>
    <Drawer >
      <Sidebar routes={routes} PaperProps={{ style: { width: drawerWidth } }} />
    </Drawer>
    <AppContent >
      <Nav />
      <CustomBox >{children}</CustomBox>
    </AppContent>
  </Root>
}