import axios from 'axios'
import { useReducer } from 'react'
import { APIPATH, getHeader, commonReducer, ACTION } from '../utils/utils'

const signInInitialState = {
    data: [],
    loading: false,
    error: null,
}

const authenticateInitialState = {
    data: [],
    loading: false,
    error: null,
}

const AuthApi = () => {
    const [signInState, signInDispatch] = useReducer(commonReducer, signInInitialState);
    const [authenticateState, authenticateDispatch] = useReducer(commonReducer, authenticateInitialState)

    const signIn = async (value, onComplete) => {
        signInDispatch({ type: ACTION.LOADING, payload: '' })
        let url = `${APIPATH}user/login`;
        let header = getHeader();
        try {
            var response = await axios.post(url, value, {
                headers: header
            })
            signInDispatch({ type: response.status === 200 ? ACTION.SUCCESS : ACTION.ERROR, payload: response.data })
            onComplete(true, false)
        }
        catch (e) {
            signInDispatch({ type: ACTION.ERROR, payload: "" })
            onComplete(false, true)
        }
    }

    const authenticate = async (token, onComplete) => {
        authenticateDispatch({ type: ACTION.LOADING, payload: '' })
        let url = `${APIPATH}user/authenticate`;
        let header = getHeader(token);
        try {
            var response = await axios.get(url, {
                headers: header
            })
            authenticateDispatch({ type: response.status === 200 ? ACTION.SUCCESS : ACTION.ERROR, payload: response.data })
            onComplete(true, false)
        }
        catch (e) {
            authenticateDispatch({ type: ACTION.ERROR, payload: "" })
            onComplete(false, true)
        }
    }

    const api = {
        signIn: { state: signInState, api: signIn },
        authenticate: { state: authenticateState, api: authenticate }
    }
    return api;
}

export default AuthApi