/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Card, CardActions, CardContent, Container, Grid, Pagination, Snackbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import BusinessApi from '../../services/businessService';
import DevicesApi from '../../services/devicesServices';
import { DeviceReplacementDialog } from '../replacement/deviceReplacement';
import DeviceInfoDialog from '../devices/devicesDialog'
import SearchIcon from '@mui/icons-material/Search'
import { DeviceUpdateDialog } from '../replacement/deviceUpdate';
import { Search, SearchIconWrapper, StyledInputBase } from '../../utils/ui_utils';
import _ from 'lodash';

function Business() {
    const [page, setPage] = useState(1);
    const [pageDevice, setPageDevice] = useState(1);
    const [deviceDialogVisibility, setDeviceDeialogVisibility] = useState(false)
    const [replacementDialogVisibility, setReplacementDialogVisiblity] = useState(false)
    const [deviceUpdateDialogVisibility, setDeviceUpdateDialogVisiblity] = useState(false)
    const [replacementDevice, setReplacementDevice] = useState({})
    const [updateDevice, setUpdateDevice] = useState({})
    const [business, setBusiness] = useState({})
    const [snackbar, setSnackBar] = useState(false)
    const [PopUpMessage, setPopUpMessage] = useState(null)
    const [severity, setSeverity] = useState(null)
    const businessAPI = BusinessApi()
    const deviceAPI = DevicesApi()
    const limit = 12;
    const limitDevice = 15
    const [busiessNameSearch, setBusiessNameSearch] = useState('')

    const onPageChange = (value) => {
        setPage(value)
        businessAPI.fetch.api(value, limit)
    }

    const onPageDeviceChange = (value) => {
        setPageDevice(value)
        deviceAPI.fetch.api(business._id, value, limitDevice)
    }

    const toastMessage = (message, severity) => {
        setPopUpMessage(message)
        setSeverity(severity)
        setSnackBar(true)
    }

    const handleSnackbarClose = () => {
        setSnackBar(false)
    }

    useEffect(() => {
        businessAPI.fetch.api(page, limit)
    }, [])

    const onDeviceInfoClick = (business) => {
        setBusiness(business)
        deviceAPI.fetch.api(business._id, pageDevice, limitDevice)
        setDeviceDeialogVisibility(true)
    }

    return <>
        <Helmet title='Awatar Dashboard - NGX Support - Businesses' />
        <Container sx={{ py: 8 }} maxWidth="lg">
            <Grid justify="space-between" container spacing={10} sx={{ pb: 5 }}>
                <Grid item>
                    <Typography variant="h6" gutterBottom display="inline">
                        Business List
                    </Typography>
                </Grid>
                <Grid item>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            value={busiessNameSearch}
                            autoFocus
                            onChange={e => {
                                setPage(1)
                                setBusiessNameSearch(e.target.value)
                                businessAPI.fetch.api(1, limit, e.target.value)
                            }}
                        />
                    </Search>
                </Grid>
            </Grid>
            {businessAPI.fetch.state.data.length > 0 ? <>
                <Grid container spacing={4}>
                    {businessAPI.fetch.state.data.map((business) => (
                        <Grid key={business._id} item xs={12} sm={6} md={4}>
                            <Card raised sx={{ borderRadius: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                        {business.businessName}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        {business.city ?? "-"}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {business.state ?? "-"}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {business.telephone ?? "-"}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" color="info" onClick={() => {
                                        onDeviceInfoClick(business)
                                    }}> Devices</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <DeviceInfoDialog
                    visibility={deviceDialogVisibility}
                    initialValues={deviceAPI.fetch.state.data}
                    onClose={() => {
                        setDeviceDeialogVisibility(false)
                    }}
                    title={"Device List -  " + business.businessName}
                    onPageChange={(value) => {
                        onPageDeviceChange(value)
                    }}
                    pagecount={deviceAPI.fetch.state.totalRecords}
                    page={pageDevice}
                    onReplaceClicked={(value) => {
                        setReplacementDevice({
                            "defectiveDeviceSerialNumber": value.serialNo,
                            "businessId": value.businessId,
                            "replacementDeviceSerialNumber": ""
                        })
                        setReplacementDialogVisiblity(true)
                    }}
                    onEditClicked={(value) => {
                        setUpdateDevice({
                            "deviceId": value._id,
                            "dealercode": value.dealercode,
                            "installedBy": value.installedBy
                        })
                        setDeviceUpdateDialogVisiblity(true)
                    }}
                />
                <DeviceReplacementDialog
                    visibility={replacementDialogVisibility}
                    initialvalue={replacementDevice}
                    onSubmit={(value) => {
                        if (value.defectiveDeviceSerialNumber === value.replacementDeviceSerialNumber) {
                            toastMessage("Serial Numbers of Defective Device and Replacement devices are same.", "error")
                            return
                        }
                        deviceAPI.replaceDevice.api(value, (success, error, responseMessage) => {
                            if (error) {
                                toastMessage(responseMessage, "error");
                                return;
                            }
                            toastMessage("Device replaced successfully", "success")
                            deviceAPI.fetch.api(business._id, page, limit)
                            setReplacementDialogVisiblity(false)
                        })
                    }}
                    onCancel={() => { setReplacementDialogVisiblity(false) }}
                />

                <DeviceUpdateDialog
                    visibility={deviceUpdateDialogVisibility}
                    initialvalue={updateDevice}
                    onSubmit={(value) => {
                        let values = _.cloneDeep(value)
                        if (values.installedBy === undefined || values.installedBy == null || values.installedBy === '')
                            delete values.installedBy
                        deviceAPI.updateDeviceInfo.api(values, (success, error, responseMessage) => {
                            if (error) {
                                toastMessage(responseMessage, 'error');
                                return;
                            }
                            toastMessage("Device Updated", "success")
                            deviceAPI.fetch.api(business._id, page, limit)
                            setDeviceUpdateDialogVisiblity(false)
                        })
                    }}
                    onCancel={() => { setDeviceUpdateDialogVisiblity(false) }}
                />

                <Pagination color='primary' page={page} sx={{ pt: 6 }} count={businessAPI.fetch.state.totalRecords} showFirstButton showLastButton onChange={async (event, value) => { onPageChange(value) }} />
                <div>
                    <Snackbar
                        open={snackbar}
                        autoHideDuration={1500}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        onClose={handleSnackbarClose}
                    >
                        <Alert onClose={handleSnackbarClose} severity={severity}>
                            {PopUpMessage}
                        </Alert>
                    </Snackbar>
                </div></>
                : <Typography variant='h6' gutterBottom display="inline">
                    No Records found
                </Typography>}
        </Container>
    </>
}

export default Business