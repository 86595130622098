import axios from 'axios'
import { useReducer } from 'react'
import { APIPATH, getHeader, commonReducer, ACTION } from '../utils/utils'

const fetchInitialState = {
    data: [],
    loading: false,
    error: false,
    success: false
}
const updateInitialState = {
    loading: false,
    error: null,
}

const replaceInitialState = {
    loading: false,
    error: null,
}

const fetchExpiredInitialState = {
    data: [],
    loading: false,
    error: null,
}

const fetchExpiringInitialState = {
    data: [],
    loading: false,
    error: null,
}

const fetchSummaryInitialState = {
    data: [],
    loading: false,
    error: null,
}


const DevicesApi = () => {
    const [fetchState, fetchDispatch] = useReducer(commonReducer, fetchInitialState);
    const [fetchExpiredState, fetchExpiredDispatch] = useReducer(commonReducer, fetchExpiredInitialState);
    const [fetchExpiringState, fetchExpiringDispatch] = useReducer(commonReducer, fetchExpiringInitialState);
    const [updateState, updateDispatch] = useReducer(
        commonReducer,
        updateInitialState
    )
    const [replaceState, replaceDispatch] = useReducer(
        commonReducer,
        replaceInitialState
    )
    const [fetchSummaryState, fetchSummaryDispatch] = useReducer(commonReducer, fetchSummaryInitialState)
    const token = localStorage.getItem("access_token");
    let header = getHeader(token);

    const fetch = async (businessId, page, limit) => {
        fetchDispatch({ type: ACTION.LOADING, payload: [] })
        let url = `${APIPATH}device/support?businessId=${businessId}&page=${page}&limit=${limit}`;
        try {
            var response = await axios.get(url, {
                headers: header
            })
            fetchDispatch({ type: ACTION.SUCCESS, payload: response.data.docs, total: response.data.pages })
        }
        catch (e) {
            fetchDispatch({ type: ACTION.ERROR, payload: [] })
        }
    }

    const fetchAllDevices = async (page, limit, serialNo) => {
        fetchDispatch({ type: ACTION.LOADING, payload: [] })
        let url = `${APIPATH}device/support/list?page=${page}&limit=${limit}`;
        if (serialNo !== undefined && serialNo !== null && serialNo.trim() !== '')
            url = `${url}&serialNo=${serialNo}`
        try {
            var response = await axios.get(url, {
                headers: header
            })
            fetchDispatch({ type: ACTION.SUCCESS, payload: response.data.docs, total: response.data.pages })
        }
        catch (e) {
            fetchDispatch({ type: ACTION.ERROR, payload: [] })
        }
    }

    const replaceDevice = async (values, onComplete) => {
        replaceDispatch({ type: ACTION.LOADING, payload: '' })
        let url = `${APIPATH}device/support/replacement`;
        try {
            var response = await axios.patch(url, values, {
                headers: header
            })
            replaceDispatch({ type: response.status === 200 ? ACTION.SUCCESS : ACTION.ERROR, payload: response.data })
            onComplete(true, false, null)
        }
        catch (e) {
            replaceDispatch({ type: ACTION.ERROR })
            onComplete(false, true, e.response.data.message)
        }
    }

    const updateDeviceInfo = async (values, onComplete) => {
        updateDispatch({ type: ACTION.LOADING, payload: '' })
        let url = `${APIPATH}device/support/update`;
        try {
            var response = await axios.patch(url, values, {
                headers: header
            })
            updateDispatch({ type: response.status === 200 ? ACTION.SUCCESS : ACTION.ERROR, payload: response.data })
            onComplete(true, false, null)
        }
        catch (e) {
            updateDispatch({ type: ACTION.ERROR })
            onComplete(false, true, e.response.data.message)
        }
    }

    const fetchExpiredDevices = async (page, limit) => {
        fetchExpiredDispatch({ type: ACTION.LOADING, payload: [] })
        let url = `${APIPATH}device/support/list/subscriptionexpired?page=${page}&limit=${limit}`;
        try {
            var response = await axios.get(url, {
                headers: header
            })
            fetchExpiredDispatch({ type: ACTION.SUCCESS, payload: response.data.docs, total: response.data.pages })

        }
        catch (e) {
            fetchExpiredDispatch({ type: ACTION.ERROR, payload: [] })
        }
    }

    const fetchExpiringDevices = async (page, limit) => {
        fetchExpiringDispatch({ type: ACTION.LOADING, payload: [] })
        let url = `${APIPATH}device/support/list/subscriptionfutureexpiry?page=${page}&limit=${limit}`;
        try {
            var response = await axios.get(url, {
                headers: header
            })
            fetchExpiringDispatch({ type: ACTION.SUCCESS, payload: response.data.docs, total: response.data.pages })

        }
        catch (e) {
            fetchExpiringDispatch({ type: ACTION.ERROR, payload: [] })
        }
    }

    const fetchSummary = async () => {
        fetchSummaryDispatch({ type: ACTION.LOADING, payload: {} })
        let url = `${APIPATH}device/support/summary`;
        try {
            var response = await axios.get(url, {
                headers: header
            })
            fetchSummaryDispatch({ type: ACTION.SUCCESS, payload: response.data, })
        }
        catch (e) {
            fetchSummaryDispatch({ type: ACTION.ERROR, payload: {} })
        }
    }

    const api = {
        fetch: { state: fetchState, api: fetch },
        fetchAllDevices: { state: fetchState, api: fetchAllDevices },
        fetchExpiredDevices: { state: fetchExpiredState, api: fetchExpiredDevices },
        fetchExpiringDevices: { state: fetchExpiringState, api: fetchExpiringDevices },
        replaceDevice: { state: replaceState, api: replaceDevice },
        updateDeviceInfo: { state: updateState, api: updateDeviceInfo },
        fetchSummary: { state: fetchSummaryState, api: fetchSummary },
    }
    return api;
}

export default DevicesApi