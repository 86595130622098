import { createTheme } from "@mui/material";
import { green, grey } from '@mui/material/colors'

const customBlue = {
  50: '#e9f0fb',
  100: '#c8daf4',
  200: '#a3c1ed',
  300: '#7ea8e5',
  400: '#6395e0',
  500: '#4782da',
  600: '#407ad6',
  700: '#376fd0',
  800: '#2f65cb',
  900: '#2052c2 ',
}

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    // h1: {
    //   fontSize: '2rem',
    //   fontWeight: 600,
    //   lineHeight: 1.25,
    // },
    // h2: {
    //   fontSize: '1.75rem',
    //   fontWeight: 600,
    //   lineHeight: 1.25,
    // },
    // h3: {
    //   fontSize: '1.5rem',
    //   fontWeight: 600,
    //   lineHeight: 1.25,
    // },
    // h4: {
    //   fontSize: '1.125rem',
    //   fontWeight: 500,
    //   lineHeight: 1.25,
    // },
    // h5: {
    //   fontSize: '1.0625rem',
    //   fontWeight: 500,
    //   lineHeight: 1.25,
    // },
    // h6: {
    //   fontSize: '1rem',
    //   fontWeight: 500,
    //   lineHeight: 1.25,
    // },
    // body1: {
    //   fontSize: 13,
    // },
    // button: {
    //   textTransform: 'none',
    // },
  },
  palette: {
    primary: {
      light: '#233044', //'#bbdefb',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  footer: {
    color: grey[500],
    background: '#FFF',
  },
  sidebar: {
    color: grey[200],
    background: '#233044',
    header: {
      color: grey[200],
      background: '#233044',
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[200],
      background: '#1E2A38',
      online: {
        background: green[500],
      },
    },
    badge: {
      color: '#FFF',
      background: customBlue[500],
    },
  },
})