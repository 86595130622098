
import { Close } from '@mui/icons-material';
import { Alert, Container, Dialog, DialogTitle, Divider, Grid, IconButton, TextField } from '@mui/material'
import { Formik } from 'formik';
import React from 'react'
import * as Yup from 'yup'
import { ActionButton } from '../../utils/ui_utils';

export const DeviceUpdateDialog = ({
    visibility,
    initialvalue,
    onSubmit,
    onCancel
}) => {
    if (initialvalue === undefined)
        initialvalue = {
            "dealercode": "",
            "installedBy": "",
            "deviceId": ""
        }

    if (initialvalue.dealercode === undefined)
        initialvalue.dealercode = ""
    if (initialvalue.installedBy === undefined)
        initialvalue.installedBy = ""

    return (
        <Dialog
            open={visibility}
            maxWidth="xs"
            fullWidth
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle sx={{ "display": "flex", "justifyContent": "space-between", alignItems: "center" }}>
                Device Update
                <IconButton
                    component="span"
                    size='small'
                    sx={{ ':hover': { backgroundColor: "red", color: "white" } }}
                    onClick={onCancel}
                    variant="contained"
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <Container >

                <Formik
                    initialValues={initialvalue}
                    validationSchema={Yup.object().shape({
                        dealercode: Yup.string().required('Dealer Code is required').min(10, "10 digits required").max(10, "10 digits required"),
                        deviceId: Yup.string().required("Device is not selected")
                    })}
                    onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                        onSubmit(values)
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (

                        <form component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            {
                                errors.isSubmitting && (
                                    <Alert mt={2} mb={1} severity="warning">
                                        {errors.submit}
                                    </Alert>
                                )
                            }

                            <Divider />
                            <Grid container spacing={3} style={{ paddingTop: 10 }}>
                                <Grid item md={6}>
                                    {initialvalue.dealercode !== "" ? <TextField
                                        disabled
                                        size='small'
                                        margin="normal"
                                        fullWidth
                                        id="dealercode"
                                        label="Dealer Code"
                                        name="dealercode"
                                        value={values.dealercode}
                                        helperText={touched.dealercode && errors.dealercode}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={Boolean(touched.dealercode && errors.dealercode)} />

                                        :
                                        <TextField
                                            size='small'
                                            margin="normal"
                                            fullWidth
                                            id="dealercode"
                                            label="Dealer Code"
                                            name="dealercode"
                                            value={values.dealercode}
                                            helperText={touched.dealercode && errors.dealercode}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.dealercode && errors.dealercode)} />
                                    }
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        size='small'
                                        margin="normal"
                                        fullWidth
                                        id="installedBy"
                                        label="Installed By"
                                        name="installedBy"
                                        value={values.installedBy}
                                        helperText={touched.installedBy && errors.installedBy}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={Boolean(touched.installedBy && errors.installedBy)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-end" sx={{ paddingTop: 3, paddingBottom: 1 }}>
                                <Grid item  >
                                    <ActionButton type="submit" disabled={isSubmitting} mt={8}>
                                        Update
                                    </ActionButton>
                                </Grid>
                            </Grid>
                        </form>

                    )}
                </Formik>
            </Container>
        </Dialog >
    );
}