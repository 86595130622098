import React from 'react'
import jwt_decode from 'jwt-decode'
import { Navigate, useLocation } from 'react-router-dom'


const decode = function (token) {
    var decoded;
    try {
        decoded = jwt_decode(token);
    } catch (err) {
        decoded = null;
    }
    return decoded;
}

const isTokenExpired = token => {
    var decodedJwt = decode(token)
    if (decodedJwt === null)
        return true
    return decodedJwt.exp < new Date()
}

export const isLoggedIn = () => {
    let token = localStorage.getItem("access_token", "")
    if (token) {
        return !isTokenExpired(token)
    }
    else {
        return false
    }
}
export const RequiredAuth = ({ children }) => {
    const location = useLocation();
    const isUserLoggedIn = isLoggedIn()
    if (!isUserLoggedIn) {
        return <Navigate to='signin' state={{ from: location }} replace />
    }
    return children;
}
