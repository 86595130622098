/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Box, Button, Card, CardContent, Container, Snackbar, TextField, Typography } from '@mui/material'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { isLoggedIn } from '../auth/requiredAuth'
import AuthApi from '../services/authService'
import { useAuth } from '../utils/auth'

const SignInPage = () => {

    const AuthAPI = AuthApi()
    const [snackbar, setSnackBar] = useState(false)
    const [PopUpMessage, setPopUpMessage] = useState(null)
    const [severity, setSeverity] = useState(null)
    const userAuth = useAuth()
    const navigate = useNavigate()
    const toastMessage = (message, severity) => {
        setPopUpMessage(message)
        setSeverity(severity)
        setSnackBar(true)
    }
    const handleSnackbarClose = () => {
        setSnackBar(false)
    }

    const handleSuccessLogin = () => {
        navigate('/business')

    }

    useEffect(() => {
        if (isLoggedIn()) {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        if (Object.keys(AuthAPI.signIn.state.data).length > 0) {
            userAuth.login(AuthAPI.signIn.state.data.token)
            handleSuccessLogin()
        }
    }, [AuthAPI.signIn.state.data])

    return (
        <>
            <Helmet title='Sign In' />
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Card raised sx={{ borderRadius: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flexGrow: 1 }}>
                            <Typography component="h1" align='center' variant="h5">
                                Sign in
                            </Typography>

                            <Formik
                                initialValues={{
                                    user: '',
                                    password: '',
                                    submit: false,
                                }}
                                validationSchema={Yup.object().shape({
                                    user: Yup.string().max(255).required('Email / Mobile Number is required'),
                                    password: Yup.string().max(255).required('Password is required'),
                                })}
                                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                                    delete values.submit;
                                    AuthAPI.signIn.api(values, (success, error) => {
                                        if (error) {
                                            toastMessage("Invalide Credentials", "error")
                                        }
                                    })
                                }}
                            >

                                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                    <form component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                        {
                                            errors.isSubmitting && (
                                                <Alert mt={2} mb={1} severity="warning">
                                                    {errors.submit}
                                                </Alert>
                                            )
                                        }
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="user"
                                            label="Email Address / Mobile"
                                            name="user"
                                            value={values.user}
                                            helperText={touched.user && errors.user}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.user && errors.user)}
                                        />
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            value={values.password}
                                            error={Boolean(touched.password && errors.password)}
                                            helperText={touched.password && errors.password}
                                            onBlur={handleBlur}
                                            onChange={handleChange}

                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            disabled={isSubmitting}
                                            sx={{ mt: 3, mb: 2 }}
                                        >
                                            Sign In
                                        </Button>
                                    </form>
                                )}
                            </Formik>
                        </CardContent>
                    </Card>
                </Box>
                <div>
                    <Snackbar
                        open={snackbar}
                        autoHideDuration={5000}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        onClose={handleSnackbarClose}
                    >
                        <Alert onClose={handleSnackbarClose} severity={severity}>
                            {PopUpMessage}
                        </Alert>
                    </Snackbar>
                </div>
            </Container >
        </>
    )
}

export default SignInPage