import { createContext, useContext } from "react";

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {

    const login = (token) => {
        localStorage.setItem("access_token", token);
    }

    const logout = () => {
        localStorage.clear();
    }
    return <AuthContext.Provider value={{ login, logout }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}