
import { Close } from '@mui/icons-material';
import { Alert, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material'
import { Formik } from 'formik';
import React from 'react'
import * as Yup from 'yup'
import { ActionButton } from '../../utils/ui_utils';

export const DeviceReplacementDialog = ({
    visibility,
    initialvalue,
    onSubmit,
    onCancel
}) => {
    if (initialvalue === undefined)
        initialvalue = {
            "defectiveDeviceSerialNumber": "",
            "replacementDeviceSerialNumber": "",
            "businessId": ""
        }

    if (initialvalue.replacementDeviceSerialNumber === undefined)
        initialvalue.replacementDeviceSerialNumber = ""

    return (
        <Dialog
            open={visibility}
            maxWidth="lg"
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle sx={{ "display": "flex", "justifyContent": "space-between", alignItems: "center" }}>
                Device Replacement
                <IconButton
                    component="span"
                    size='small'
                    sx={{ ':hover': { backgroundColor: "red", color: "white" } }}
                    onClick={onCancel}
                    variant="contained"
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography variant='body1' gutterBottom>Defective Serial No: {initialvalue.defectiveDeviceSerialNumber}</Typography>
                <Formik
                    initialValues={initialvalue}
                    validationSchema={Yup.object().shape({
                        defectiveDeviceSerialNumber: Yup.string().required('Serial Number of defective Device is required'),
                        replacementDeviceSerialNumber: Yup.string().min(12, "Serial Number should be min 12 characters").max(20).required('Serial Number of replacement Device is required'),
                        businessId: Yup.string().required("Business ID is requried")
                    })}
                    onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                        onSubmit(values)
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (

                        <form component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            {
                                errors.isSubmitting && (
                                    <Alert mt={2} mb={1} severity="warning">
                                        {errors.submit}
                                    </Alert>
                                )
                            }
                            <TextField
                                size='small'
                                margin="normal"
                                fullWidth
                                id="replacementDeviceSerialNumber"
                                label="Serial Number"
                                name="replacementDeviceSerialNumber"
                                value={values.replacementDeviceSerialNumber}
                                helperText={touched.replacementDeviceSerialNumber && errors.replacementDeviceSerialNumber}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.replacementDeviceSerialNumber && errors.replacementDeviceSerialNumber)}
                            />
                            <Grid container justifyContent="flex-end">
                                <Grid item  >
                                    <ActionButton type="submit" disabled={isSubmitting} mt={8}>
                                        Replace
                                    </ActionButton>
                                </Grid>
                            </Grid>
                        </form>

                    )}
                </Formik>
            </DialogContent>
        </Dialog >
    );
}