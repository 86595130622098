import React from 'react'

import { Route, Routes } from "react-router-dom";
import Page404 from "./pages/Page404";
import SignInPage from "./pages/signIn";
import { Dashboard } from './components/Dashboard';
import { dashboardRoutes } from './routes/index';

const childRoutes = (Layout, routes) =>
  routes.map(({ component: Component, guard, children, path }, index) => {
    const Guard = guard || React.Fragment
    return children ? (
      children.map((element, index) => {
        const Guard = element.guard || React.Fragment
        const ElementComponent = element.component || React.Fragment
        return (
          <Route
            key={index}
            path={element.path}
            exact
            element={
              <Layout>
                <Guard>
                  <ElementComponent />
                </Guard>
              </Layout>
            }
          />
        )
      })
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        element={
          <Layout>
            <Guard>
              <Component />
            </Guard>
          </Layout>
        }
      />
    ) : null
  })

function App() {
  return (
    <Routes>
      <Route path='/' element={<SignInPage />} />
      <Route path="signin" element={<SignInPage />} />
      {childRoutes(Dashboard, dashboardRoutes)}
      <Route path="*" element={<Page404 />} />
    </Routes>
  )
}

export default App;
