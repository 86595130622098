/* eslint-disable react-hooks/exhaustive-deps */
import styled from '@emotion/styled'
import { Box, Card, alpha, Grid, Pagination, Table, TableBody, TableHead, Typography, InputBase, TableContainer, CardContent, Divider, Button } from '@mui/material'
import { Container } from '@mui/system'
import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import SearchIcon from '@mui/icons-material/Search'
import DevicesApi from '../../services/devicesServices'
import { StyledTableRow, StyledTableCell } from '../../utils/ui_utils'
import { saveAs } from 'file-saver'
import { Download } from '@mui/icons-material'

export const Devices = () => {
    const [page, setPage] = useState(1);
    const [pageExpired, setPageExpired] = useState(1);
    const [pageExpiring, setPageExpiring] = useState(1);
    const [deviceSerached, setDeviceSearched] = useState(false)
    const limit = 10
    const exportLimit = 50
    const [exportPage, setExportPage] = useState(1)
    let exportTotalPages = 0;
    const devicesAPI = DevicesApi();
    const devicesExportAPI = DevicesApi();
    const [deviceSerialNumber, setDeviceSerialNumber] = useState('')
    // eslint-disable-next-line
    const [exportDataForCsv, setExportDataForCsv] = useState([])
    const [exportInProgress, setExportInProgress] = useState(false);

    const onPageChange = (value) => {
        setPage(value)
        devicesAPI.fetchAllDevices.api(value, limit)
    }

    const onPageExpiredChange = (value) => {
        setPageExpired(value)
        devicesAPI.fetchExpiredDevices.api(value, limit)
    }

    const onPageExpiringChange = (value) => {
        setPageExpiring(value)
        devicesAPI.fetchExpiringDevices.api(value, limit)
    }

    const onExportClicked = () => {
        if (!exportInProgress) {
            setExportInProgress(true)
            setExportPage(1)
            devicesExportAPI.fetchAllDevices.api(1, exportLimit);
        }
    }

    const processExportData = (data) => {
        if (exportPage <= exportTotalPages) {
            let page = exportPage;
            setExportPage(exportPage + 1)
            let tempObj = {}
            // eslint-disable-next-line array-callback-return
            data.map((device) => {
                tempObj = {}
                tempObj.SerialNo = `${device.serialNo}`
                tempObj.DeviceName = device.userDeviceName ?? "-"
                tempObj.Dealer = device.dealer.businessName ?? "-"
                tempObj.Dealer_Mobile = device.dealer.mobile ?? "-"
                tempObj.Dealer_Code = device.dealer.code ?? "-"
                tempObj.Business = device.business.businessName
                tempObj.Contact = device.business.telephone ?? "-"
                tempObj.City = device.business.city ?? "-"
                tempObj.Warranty = device.warrantyExpireDate !== undefined ? moment(new Date(device.warrantyExpireDate)).format("DD/MM/YYYY") : "-"
                tempObj.Subscription = device.subscriptionExpiry !== undefined ? moment(new Date(device.subscriptionExpiry)).format("DD/MM/YYYY") : "-"
                tempObj.LastSync = device.lastSyncBillDate !== undefined ? moment(new Date(device.lastSyncBillDate)).format("DD/MM/YYYY") : "-"
                tempObj.ReplacedWith = device.replacedDeviceSerialNumber !== undefined ? `${device.replacedDeviceSerialNumber}` : ""
                exportDataForCsv.push(tempObj)
            })
            devicesExportAPI.fetchAllDevices.api(page, exportLimit);
        }
        else {
            downloadReportFile("Awatar Devices.csv");
        }
    }

    const downloadReportFile = (fileName) => {
        const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
        const header = Object.keys(exportDataForCsv[0]);
        let csv = exportDataForCsv.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        let csvArray = csv.join('\r\n');

        var blob = new Blob([csvArray], { type: 'text/csv' })
        saveAs(blob, fileName);
        setExportInProgress(false)
    }

    useEffect(() => {
        if (devicesExportAPI.fetchAllDevices.state.success === true) {
            exportTotalPages = devicesExportAPI.fetchAllDevices.state.totalRecords
            processExportData(devicesExportAPI.fetchAllDevices.state.data)
        }

    }, [devicesExportAPI.fetchAllDevices.state.data])

    useEffect(() => {
        devicesAPI.fetchSummary.api()
        devicesAPI.fetchAllDevices.api(page, limit)
        devicesAPI.fetchExpiredDevices.api(pageExpired, limit)
        devicesAPI.fetchExpiringDevices.api(pageExpiring, limit)
    }, [])

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius * 2,
        borderColor: theme.palette.primary.light,
        backgroundColor: alpha(theme.palette.primary.light, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '50%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: '50%',
        },
    }));

    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        // color: 'inherit',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '50ch',
            },
        },
    }));

    return (
        <>
            <Helmet title='Awatar Dashboard - NGX Support - Devices' />
            <Container maxWidth="xl" >
                <Grid container spacing={5} sx={{ p: 1, pt: 5, alignItems: "center" }} direction="column" >
                    <Grid item >
                        <Grid container spacing={4}>
                            <Grid xs={12} sm={6} md={3} item>
                                <Box sx={{ width: 200, height: 150 }}>
                                    <Card raised sx={{ borderRadius: 3, height: '100%', flexDirection: 'column' }}>
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Typography align='center' component="div" gutterBottom>
                                                Total Devices
                                            </Typography>
                                            <Typography variant='h3' align='center' gutterBottom >
                                                {devicesAPI.fetchSummary.state.data.totalDevices ?? "0"}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                            <Grid xs={12} sm={6} md={3} item>
                                <Box sx={{ width: 200, height: 150 }}>
                                    <Card raised sx={{ borderRadius: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Typography variant="body1" align='center' gutterBottom>
                                                Replaced Devices
                                            </Typography>
                                            <Typography variant='h3' align='center' gutterBottom >
                                                {devicesAPI.fetchSummary.state.data.totalReplaced ?? "0"}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                            <Grid xs={12} sm={6} md={3} item>
                                <Box sx={{ width: 200, height: 150 }}>

                                    <Card raised sx={{ borderRadius: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Typography align='center' component="div" gutterBottom>
                                                Subscription Expired
                                            </Typography>
                                            <Typography variant='h3' align='center' gutterBottom >
                                                {devicesAPI.fetchSummary.state.data.totalExpired ?? "0"}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                            <Grid xs={12} sm={6} md={3} item>
                                <Box sx={{ width: 200, height: 150 }}>
                                    <Card raised sx={{ borderRadius: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Typography align='center' component="div" gutterBottom>
                                                Subscription Expiring
                                            </Typography>
                                            <Typography variant='h3' align='center' gutterBottom >
                                                {devicesAPI.fetchSummary.state.data.totalExpiring ?? "0"}
                                            </Typography>
                                            <Typography variant='body2' align='center' gutterBottom >
                                                within two months
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider sx={{ m: 3 }} />
                    <Grid item  >
                        <Box sx={{ margin: 2 }}>
                            <Card sx={{ p: 2 }} >
                                <Grid display="flex" justify="space-between" container sx={{ pb: 2, }}>
                                    <Grid item>
                                        <Typography variant="h6" gutterBottom display="inline">
                                            Devices
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <Search>
                                            <SearchIconWrapper>
                                                <SearchIcon />
                                            </SearchIconWrapper>
                                            <StyledInputBase
                                                placeholder="Search…"
                                                inputProps={{ 'aria-label': 'search' }}
                                                value={deviceSerialNumber}
                                                autoFocus
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        setPage(1)
                                                        setDeviceSearched(true)
                                                        devicesAPI.fetchAllDevices.api(1, limit, e.target.value)
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    setDeviceSerialNumber(e.target.value)
                                                    if (!e.target.value) {
                                                        if (deviceSerached) {
                                                            setDeviceSearched(false)
                                                            setPage(1)
                                                            devicesAPI.fetchAllDevices.api(1, limit)
                                                        }
                                                    }
                                                }}
                                            />
                                        </Search>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            size="medium"
                                            color="secondary"
                                            disabled={exportInProgress}
                                            startIcon={<Download />}
                                            onClick={() => { onExportClicked() }}
                                            variant="outlined"
                                        >
                                            {exportInProgress === false ? "Export" : "Downloading..."}
                                        </Button>

                                    </Grid>
                                </Grid>
                                <Grid container >
                                    <TableContainer component={Card}>
                                        <Table size="small">
                                            <TableHead >
                                                <StyledTableRow >
                                                    <StyledTableCell align="left">Serial Number</StyledTableCell>
                                                    <StyledTableCell align="left">Device Name</StyledTableCell>
                                                    <StyledTableCell align="left">Dealer</StyledTableCell>
                                                    <StyledTableCell align="center">Dealer Code</StyledTableCell>
                                                    <StyledTableCell align="left">Business</StyledTableCell>
                                                    <StyledTableCell align="left">Contact No.</StyledTableCell>
                                                    <StyledTableCell align="center">City</StyledTableCell>
                                                    <StyledTableCell align="center">Warranty</StyledTableCell>
                                                    <StyledTableCell align="center">Subscription</StyledTableCell>
                                                    <StyledTableCell align="center">Last Sync</StyledTableCell>
                                                    <StyledTableCell align="center">Replaced</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    devicesAPI.fetchAllDevices.state.data.map((row) => (
                                                        
                                                        <StyledTableRow key={row._id} hover >
                                                            <StyledTableCell align='left' scope='row'>
                                                                {row.serialNo}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left' >
                                                                {row.userDeviceName ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left' scope='row'>
                                                                {row.dealer.businessName ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' scope='row'>
                                                                {row.dealercode ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left' scope='row'>
                                                                {row.business.businessName ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' scope='row'>
                                                                {row.business.telephone ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' scope='row'>
                                                                {row.business.city ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' scope='row'>
                                                                {(row.warrantyExpireDate !== undefined) ? moment.utc(new Date(row.warrantyExpireDate).toUTCString()).format("DD/MM/YYYY") : "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' scope='row'>
                                                                {(row.subscriptionExpiry !== undefined) ? moment.utc(new Date(row.subscriptionExpiry).toUTCString()).format("DD/MM/YYYY") : "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' scope='row'>
                                                                {(row.lastSyncBillDate !== undefined) ? moment.utc(new Date(row.lastSyncBillDate).toUTCString()).format("DD/MM/YYYY") : "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' scope='row'>
                                                                {row.replacedDeviceSerialNumber ?? "-"}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <div style={{ marginTop: 5, marginBottom: 5 }}>
                                        <Pagination color='primary' page={page} sx={{ pt: 6 }} count={devicesAPI.fetch.state.totalRecords} showFirstButton showLastButton onChange={async (event, value) => { onPageChange(value) }} />
                                    </div>
                                </Grid>
                            </Card>

                        </Box>
                    </Grid>
                    <Grid item  >
                        <Box sx={{ margin: 2 }}>
                            <Card sx={{ p: 2 }} >
                                <Grid justify="space-between" container spacing={10} sx={{ pb: 2, }}>
                                    <Grid item>
                                        <Typography variant="h6" gutterBottom display="inline">
                                            Subscription Expired Devices
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container  >
                                    <TableContainer component={Card}>

                                        <Table size="small">
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell align="left">Serial Number</StyledTableCell>
                                                    <StyledTableCell padding="none" align="left">Device Name</StyledTableCell>
                                                    <StyledTableCell align="left">Installed By</StyledTableCell>
                                                    <StyledTableCell align="left">Business</StyledTableCell>
                                                    <StyledTableCell align="left">Contact No.</StyledTableCell>
                                                    <StyledTableCell align="center">City</StyledTableCell>
                                                    <StyledTableCell align="center">Warranty</StyledTableCell>
                                                    <StyledTableCell align="center">Subscription</StyledTableCell>
                                                    <StyledTableCell align="center">Last Sync</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    devicesAPI.fetchExpiredDevices.state.data.map((row) => (
                                                        <StyledTableRow key={row._id} hover >
                                                            <StyledTableCell align='left' scope='row'>
                                                                {row.serialNo}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left' scope='row'>
                                                                {row.userDeviceName ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left' scope='row'>
                                                                {row.installedBy ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left' scope='row'>
                                                                {row.business.businessName ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left' scope='row'>
                                                                {row.business.telephone ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' scope='row'>
                                                                {row.business.city ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' scope='row'>
                                                                {(row.warrantyExpireDate !== undefined) ? moment.utc(new Date(row.warrantyExpireDate).toUTCString()).format("DD/MM/YYYY") : "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' scope='row'>
                                                                {(row.subscriptionExpiry !== undefined) ? moment.utc(new Date(row.subscriptionExpiry).toUTCString()).format("DD/MM/YYYY") : "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' scope='row'>
                                                                {(row.lastSyncBillDate !== undefined) ? moment.utc(new Date(row.lastSyncBillDate).toUTCString()).format("DD/MM/YYYY") : "-"}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <div style={{ marginTop: 5, marginBottom: 5 }}>
                                        <Pagination color='primary' page={pageExpired} sx={{ pt: 6 }} count={devicesAPI.fetchExpiredDevices.state.totalRecords} showFirstButton showLastButton onChange={async (event, value) => { onPageExpiredChange(value) }} />
                                    </div>
                                </Grid>
                            </Card>

                        </Box>
                    </Grid>
                    <Grid item  >
                        <Box sx={{ margin: 2 }}>
                            <Card sx={{ p: 2 }} >
                                <Grid justify="space-between" container spacing={10} sx={{ pb: 2, }}>
                                    <Grid item>
                                        <Typography variant="h6" gutterBottom display="inline">
                                            Subscription Expiring Devices
                                        </Typography>
                                        <Typography variant="caption" gutterBottom display="inline">
                                            {" (within 2 months)"}
                                        </Typography>

                                    </Grid>
                                </Grid>
                                <Grid container  >
                                    <TableContainer component={Card}>

                                        <Table size="small">
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell align="left">Serial Number</StyledTableCell>
                                                    <StyledTableCell padding="none" align="left">Device Name</StyledTableCell>
                                                    <StyledTableCell align="left">Installed By</StyledTableCell>
                                                    <StyledTableCell align="left">Business Name</StyledTableCell>
                                                    <StyledTableCell align="left">Contact No.</StyledTableCell>
                                                    <StyledTableCell align="center">City</StyledTableCell>
                                                    <StyledTableCell align="center">Warranty</StyledTableCell>
                                                    <StyledTableCell align="center">Subscription</StyledTableCell>
                                                    <StyledTableCell align="center">Last Sync</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    devicesAPI.fetchExpiringDevices.state.data.map((row) => (
                                                        <StyledTableRow key={row._id} hover >
                                                            <StyledTableCell align='left' scope='row'>
                                                                {row.serialNo}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left' scope='row'>
                                                                {row.userDeviceName ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left' scope='row'>
                                                                {row.installedBy ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left' scope='row'>
                                                                {row.business.businessName ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left' scope='row'>
                                                                {row.business.telephone ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' scope='row'>
                                                                {row.business.city ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' scope='row'>
                                                                {(row.warrantyExpireDate !== undefined) ? moment.utc(new Date(row.warrantyExpireDate).toUTCString()).format("DD/MM/YYYY") : "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' scope='row'>
                                                                {(row.subscriptionExpiry !== undefined) ? moment.utc(new Date(row.subscriptionExpiry).toUTCString()).format("DD/MM/YYYY") : "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' scope='row'>
                                                                {(row.lastSyncBillDate !== undefined) ? moment.utc(new Date(row.lastSyncBillDate).toUTCString()).format("DD/MM/YYYY") : "-"}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <div style={{ marginTop: 5, marginBottom: 5 }}>
                                        <Pagination color='primary' page={pageExpiring} sx={{ pt: 6 }} count={devicesAPI.fetchExpiringDevices.state.totalRecords} showFirstButton showLastButton onChange={async (event, value) => { onPageExpiringChange(value) }} />
                                    </div>
                                </Grid>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
