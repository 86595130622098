import React, { useState } from 'react'
import styled from '@emotion/styled'
import { rgba, darken } from 'polished'
import { NavLink } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import './perfect-scrollbar.css'

import {
    Box as MuiBox,
    Chip,
    Collapse,
    Drawer as MuiDrawer,
    ListItemIcon,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    Typography,
} from '@mui/material'

import { ExpandLess, ExpandMore } from '@mui/icons-material'

import { sidebarRoutes } from '../routes/index'

const Box = styled(MuiBox)(({ theme }) => ({
    margin: theme.spacing(1)
}));

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`
const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  justify-content: center;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`
const Category1 = styled(ListItemButton)(({ theme }) => ({
    //margin: "2",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(3),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.sidebar.color,

    svg: {
        color: theme.sidebar.color,
        fontSize: "20",
        width: "20",
        height: "20",
        opacity: "0.5"
    },
    "&:hover": {
        backgroundColor: darken(0.015, theme.sidebar.background)
    },

    '&.Mui-selected': {
        backgroundColor: darken(0.03, theme.sidebar.background),

        span: {
            color: theme.sidebar.color
        }
    }

}));


const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`

const SubMenuLink = styled(ListItemButton)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.sidebar.color,
    svg: {
        color: theme.sidebar.color,
        fontSize: "20",
        width: "20",
        height: "20",
        opacity: "0.5"
    },

    span: { color: rgba(theme.sidebar.color, 0.7) },
    "&:hover": {
        backgroundColor: darken(0.015, theme.sidebar.background)
    },

    '&.Mui-selected': {
        backgroundColor: darken(0.03, theme.sidebar.background),

        span: {
            color: theme.sidebar.color
        }
    }

}));


const LinkBadge = styled(Chip)`
    font - size: 11px;
    font - weight: ${(props) => props.theme.typography.fontWeightBold};
    height: 20px;
    position: absolute;
    right: 28px;
    top: 8px;
    background: ${(props) => props.theme.sidebar.badge.background};

    span.MuiChip - label,
    span.MuiChip - label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding - left: ${(props) => props.theme.spacing(1)} px;
    padding - right: ${(props) => props.theme.spacing(1)} px;
    }
`

const SidebarSection = styled(Typography)`
    color: ${(props) => props.theme.sidebar.color};
    padding: ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(2)} px
        ${(props) => props.theme.spacing(1)} px;
    opacity: 0.9;
    display: block;
`

let routes = []

function SidebarCategory({ name, icon, classes, isOpen, isCollapsable, badge, ...rest }) {
    return (

        <Category1 disableGutters {...rest}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText>
                {name}
            </ListItemText>
            {isCollapsable ? isOpen ? <CategoryIconMore /> : <CategoryIconLess /> : null}
        </Category1>
    )
}

function SidebarLink({ name, to, badge, icon }) {
    return (
        <SubMenuLink dense component={NavLink} to={to}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : ''}
            <ListItemText inset > {name}</ListItemText>
            {badge ? <LinkBadge label={badge} /> : ''}
        </SubMenuLink>
    )
}

function Sidebar({ classes, staticContext, location, ...rest }) {
    const [openRoutes, setOpenRoutes] = useState({})
    const toggle = (index) => {
        // Collapse all elements
        Object.keys(openRoutes).forEach(
            (item) =>
                openRoutes[index] || setOpenRoutes((openRoutes) => ({ ...openRoutes, [item]: false }))
        )

        // Toggle selected element
        setOpenRoutes((openRoutes) => ({
            ...openRoutes,
            [index]: !openRoutes[index],
        }))
    }

    routes = sidebarRoutes
    return (
        <Drawer variant="permanent" {...rest}>
            <Brand component={NavLink} to="/business" button>
                <Box>
                    <img alt="NGX" src="/logo.png" width="110" height="33" />
                </Box>
            </Brand>
            <Scrollbar>
                <List >
                    <Items>
                        {routes.map((category, index) => (
                            <React.Fragment key={index}>
                                {category.header ? <SidebarSection>{category.header}</SidebarSection> : null}

                                {category.children && category.icon ? (
                                    <React.Fragment key={index}>
                                        <SidebarCategory
                                            isOpen={!openRoutes[index]}
                                            isCollapsable
                                            name={category.id}
                                            icon={category.icon}

                                            onClick={() => toggle(index)}
                                        />

                                        <Collapse in={openRoutes[index]} timeout="auto" unmountOnExit>
                                            {category.children.map((route, index) => (
                                                <SidebarLink
                                                    key={index}
                                                    name={route.name}
                                                    to={route.path}
                                                    icon={route.icon}
                                                    badge={route.badge}
                                                />
                                            ))}
                                        </Collapse>
                                    </React.Fragment>
                                ) : category.icon ? (
                                    <SidebarCategory
                                        isCollapsable={false}
                                        name={category.id}
                                        to={category.path}
                                        component={NavLink}
                                        icon={category.icon}
                                        badge={category.badge}
                                    />
                                ) : null}
                            </React.Fragment>
                        ))}
                    </Items>
                </List>
            </Scrollbar>
        </Drawer>
    )
}

export default Sidebar
