import { Close, Edit } from "@mui/icons-material";
import {
  Button,
  Card,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { StyledTableCell, StyledTableRow } from "../../utils/ui_utils";

const DevicesDialog = ({
  initialValues,
  visibility,
  onClose,
  title,
  onPageChange,
  pagecount,
  page,
  onReplaceClicked,
  onEditClicked,
}) => {
  if (initialValues === undefined) initialValues = [];

  return (
    <Dialog
      open={visibility}
      maxWidth="lg"
      fullWidth
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {title}
        <IconButton
          component="span"
          size="small"
          sx={{ ":hover": { backgroundColor: "red", color: "white" } }}
          onClick={onClose}
          variant="contained"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />

      <Card sx={{ margin: 3 }}>
        <TableContainer
          component={Card}
          style={{ maxHeight: "70vh", overflow: "auto" }}
        >
          <Table size="small">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="left">Serial Number</StyledTableCell>
                <StyledTableCell align="left">Device Name</StyledTableCell>
                <StyledTableCell align="left">Dealer Code</StyledTableCell>
                <StyledTableCell align="left">Installed By</StyledTableCell>
                <StyledTableCell align="left">Warranty Till</StyledTableCell>
                <StyledTableCell align="left">
                  Subscription Till
                </StyledTableCell>
                <StyledTableCell align="left">Last Sync Date</StyledTableCell>
                <StyledTableCell align="left">Replacement</StyledTableCell>
                <StyledTableCell align="left">Edit</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {initialValues.map((row) => (
                <StyledTableRow key={row._id} hover>
                  <StyledTableCell align="left">{row.serialNo}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.userDeviceName ?? "-"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.dealercode ?? "-"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.installedBy ?? "-"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.warrantyExpireDate !== undefined
                      ? moment
                          .utc(new Date(row.warrantyExpireDate).toUTCString())
                          .format("DD/MM/YYYY")
                      : "_"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.subscriptionExpiry !== undefined
                      ? moment
                          .utc(new Date(row.subscriptionExpiry).toUTCString())
                          .format("DD/MM/YYYY")
                      : "_"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.lastSyncBillDate !== undefined
                      ? moment
                          .utc(new Date(row.lastSyncBillDate).toUTCString())
                          .format("DD/MM/YYYY")
                      : "_"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.replacedBy === undefined ? (
                      <Button
                        onClick={() => {
                          onReplaceClicked(row);
                        }}
                      >
                        Replace
                      </Button>
                    ) : (
                      row.replacedDeviceSerialNumber
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Edit
                      fontSize="small"
                      color="primary"
                      onClick={() => {
                        onEditClicked(row);
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{ marginTop: 5, marginBottom: 5 }}>
          <Pagination
            color="primary"
            page={page}
            count={pagecount}
            showFirstButton
            showLastButton
            onChange={async (event, value) => {
              onPageChange(value);
            }}
          />
        </div>
      </Card>
    </Dialog>
  );
};

export default DevicesDialog;
